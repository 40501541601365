import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import ReactPlayer from 'react-player';
import Grid from '@mui/material/Grid2';

const APIURL = process.env.REACT_APP_API_URL;

function ViewVideo() {
    const token = sessionStorage.getItem("supratvtoken") || "";
    const { seriehash } = useParams<string>();
    const navigate = useNavigate();
    const [episodeUrl, setEpisodeUrl] = useState<string>("");
    const [loading, setLoading] = React.useState(true);
    function gettvserie(seriehash: string) {
        setLoading(true);
        axios.get(`${APIURL}/supratv/episode/${seriehash}`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data[0]?.tveurl) {
                        setEpisodeUrl(response.data[0].tveurl);
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching TV series:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!sessionStorage.getItem('supratvtoken')) {
            navigate('/login');
        } else {
            gettvserie(seriehash!);
        }
    }, [navigate, seriehash]);

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100vh' }}
            >
                <Grid size={12}>
                    {loading ? (
                        <div>Loading...</div>
                    ) : episodeUrl ? (
                        <ReactPlayer
                            playing={true}
                            url={"http://10.1.80.10/series/" + episodeUrl}
                            width="100%"
                            height="100%"
                            controls
                        />
                    ) : (
                        <div>No video available</div>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default ViewVideo;