import React, { useState } from 'react';
import { ThemeProvider, CssBaseline, TextField, Grid2, Typography, Button, Modal, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, InputLabel, OutlinedInput, InputAdornment, IconButton, FormControl, Alert, Container } from '@mui/material';
import { SupraTVTheme } from '../components/SupraTVTheme';
import LoginIcon from '@mui/icons-material/Login';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const APIURL = process.env.REACT_APP_API_URL;

function LoginPage() {
    const [loading, setLoading] = useState(false);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const handleOpenRegister = () => setOpenRegister(true);
    const handleCloseRegister = () => setOpenRegister(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const navigate = useNavigate();

    const handleSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        axios.post(APIURL + "/supratv/login", formJson, { headers: { 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    sessionStorage.setItem('supratvuser', response.data.username);
                    sessionStorage.setItem('supratvtoken', response.data.token);
                    navigate("/");
                }
            })
            .catch((e: any) => {
                console.log(e.response);
                setInvalidLogin(true);
                setLoading(false);
            });
    }

    return (
        <ThemeProvider theme={SupraTVTheme}>
            <CssBaseline />
            <Dialog
                open={openRegister}
                onClose={handleCloseRegister}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        setLoading(true);
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        await axios.post(APIURL + "/supratv/register", formJson, { withCredentials: false, headers: { 'Content-Type': 'application/json' } })
                            .then((response: any) => {
                                if (response.status === 200) {
                                }
                                else {
                                }
                                setLoading(false);
                            })
                            .catch((e: any) => {
                                setLoading(false);
                            });

                        handleCloseRegister();
                    },
                }}
            >
                <DialogTitle>Register</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        This is a private site, and new accounts needs approvement after registration.
                    </DialogContentText>
                    <Grid2 size={12} mb={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="username">
                                Username
                            </InputLabel>
                            <OutlinedInput
                                id="username"
                                name="username"
                                type="text"
                                label="Username"
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2 size={12} mb={2}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRegister} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                    <Button type="submit" variant="outlined" startIcon={<BorderColorIcon />}>Register</Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <Container sx={{ mt: 2 }}>
                    <Grid2 container spacing={2} justifyContent="center" alignItems="center">
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <Box component="form" onSubmit={handleSubmitLogin}>
                                <Typography variant="h4" gutterBottom align="center">
                                    SupraTV
                                </Typography>
                                {(invalidLogin === true) &&
                                    <Alert severity="error">Invalid login.</Alert>
                                }
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="username">
                                        Username
                                    </InputLabel>
                                    <OutlinedInput
                                        id="username"
                                        name="username"
                                        type="text"
                                        label="Username"
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth sx={{ mt: 2, mb: 2 }}>
                                    <InputLabel htmlFor="password">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onMouseUp={handleMouseUpPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                <Button color="success" variant="outlined" startIcon={<LoginIcon />} type="submit" sx={{ mr: 2 }}>
                                    Login
                                </Button>
                                <Button color="secondary" variant="outlined" startIcon={<BorderColorIcon />} onClick={handleOpenRegister}>
                                    Register
                                </Button>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Container>
            )
            }
        </ThemeProvider >
    );
}

export default LoginPage;