import { Box, Button, Card, CardContent, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Paper, Select, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useCallback, useState, useRef, RefObject } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SupraTVHeader from '../components/SupraTVHeader';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SupraTVFrontImage from '../components/SupraTVFrontImage';
import TagIcon from '@mui/icons-material/Tag';
import { SelectChangeEvent } from '@mui/material/Select';
import SupraTVHorizontalScroll from '../components/SupraTVHorizontalScroll';

const APIURL = process.env.REACT_APP_API_URL;

function TVSerie() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const token = sessionStorage.getItem("supratvtoken") || "";
    const { serieid } = useParams<string>();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [series, setSeries] = useState<any[]>([]);
    const [tagOptions, setTagOptions] = useState<any[]>([]);
    const [TVTags, setTVTags] = React.useState<string[]>([]);
    const [activeSeasonId, setActiveSeasonId] = useState<number>(0);
    const [activeSeasonName, setActiveSeasonName] = useState<string>("0");
    const [seriesLanguage, setSeriesLanguage] = useState<string>('English');
    const [imageUrl, setImageUrl] = useState<string>(`https://media.ghosthunters.no/tv/header/${serieid}.jpg?timestamp=${Date.now()}`);
    const [imageFrontUrl, setImageFrontUrl] = useState<string>(`https://media.ghosthunters.no/tv/front/${serieid}.jpg?timestamp=${Date.now()}`);

    const [files, setFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
    const [currentUploadIndex, setCurrentUploadIndex] = useState<number>(0); // Track the current file being uploaded





    function gettvseries() {
        axios.get(APIURL + "/supratv/serie/" + serieid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setSeries(response.data);
                    setSeriesLanguage(response.data[0].tvslanguage);
                }
                setLoading(false);
            })
            .catch((e: Error) => {
                setLoading(false);
            });
    }

    function getTagOptions() {
        axios.get(APIURL + "/supratv/tagoptions", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTagOptions(response.data);
                }
                setLoading(false);
            })
            .catch((e: Error) => {
                setLoading(false);
            });
    }

    // Dropzone for banner image
    const onDropBanner = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        event.stopPropagation(); // Ensure the event doesn't bubble up
        const file = acceptedFiles[0];
        handleUploadBanner(file);
    }, []);

    const { getRootProps: getRootPropsBanner, getInputProps: getInputPropsBanner } = useDropzone({
        onDrop: onDropBanner,
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
    });

    // Dropzone for front image
    const onDropFront = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        event.stopPropagation(); // Ensure the event doesn't bubble up to the banner dropzone
        const file = acceptedFiles[0];
        handleUploadFront(file);
    }, []);

    const { getRootProps: getRootPropsFront, getInputProps: getInputPropsFront } = useDropzone({
        onDrop: onDropFront,
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
    });

    const onDropEpisode = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        event.stopPropagation();
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]); // Add files to queue

        if (!uploading) {
            setCurrentUploadIndex(0); // Reset to first file
            handleUploadNextFile(0, [...acceptedFiles]); // Start uploading the first file
        }
    }, [uploading, activeSeasonId, activeSeasonName]);

    const handleUploadNextFile = async (index: number, fileQueue: File[]) => {
        if (index >= fileQueue.length) {
            // If all files are uploaded, reset the state and finish
            setUploading(false);
            handleAllFilesUploaded();
            return;
        }

        const file = fileQueue[index];
        setUploading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('token', token);
        if (serieid) {
            formData.append('tvseriesid', serieid);
        }
        formData.append('tvseasonid', activeSeasonId.toString());
        formData.append('tvseasonname', activeSeasonName);

        try {
            await axios.post("http://10.1.80.10/supratv-uploadepisode.php", formData, {
                withCredentials: false,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (event) => {
                    if (event.total) {
                        const progress = Math.round((event.loaded * 100) / event.total);
                        setUploadProgress(prevProgress => ({
                            ...prevProgress,
                            [file.name]: progress
                        }));
                    }
                },
            });

            setUploadProgress(prevProgress => ({
                ...prevProgress,
                [file.name]: 100
            }));

            // Move to the next file
            setCurrentUploadIndex(index + 1);
            handleUploadNextFile(index + 1, fileQueue); // Recursively call the next file upload

        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    // Trigger function when all files are uploaded
    const handleAllFilesUploaded = () => {
        handleCloseAddEpisodeModal();
        gettvseries(); // Trigger the required actions after all files are uploaded
    };

    const { getRootProps: getRootPropsEpisode, getInputProps: getInputPropsEpisode, isDragActive } = useDropzone({
        onDrop: onDropEpisode,
        accept: {
            'video/*': []
        },
        maxFiles: 100, // This allows up to 100 files to be uploaded
    });

    const handleUploadBanner = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('token', token);
        if (serieid) {
            formData.append('tvsid', serieid);
        }
        try {
            await axios.post("https://media.ghosthunters.no/tvbannerup.php", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImageUrl(`https://media.ghosthunters.no/tv/header/${serieid}.jpg?timestamp=${Date.now()}`);
        } catch (error) {
        }
    };

    const handleUploadFront = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('token', token);
        if (serieid) {
            formData.append('tvsid', serieid);
        }
        try {
            await axios.post("https://media.ghosthunters.no/tvfrontup.php", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setImageFrontUrl(`https://media.ghosthunters.no/tv/front/${serieid}.jpg?timestamp=${Date.now()}`);
        } catch (error) {
        }
    };

    const handleDeleteTag = async (tagid: number) => {
        await axios.delete(APIURL + "/supratv/tags/" + tagid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then(() => {
                gettvseries();
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
            });
    };

    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const [openAddSeasonModal, setOpenAddSeasonModal] = React.useState(false);
    const handleOpenAddSeasonModal = () => setOpenAddSeasonModal(true);
    const handleCloseAddSeasonModal = () => setOpenAddSeasonModal(false);

    const [openAddTVEpisodeModal, setopenAddTVEpisodeModal] = React.useState(false);
    const handleOpenAddEpisodeModal = (seasonid: any, seasonname: any) => {
        setActiveSeasonId(seasonid);
        setActiveSeasonName(seasonname)
        setopenAddTVEpisodeModal(true);
    }
    const handleCloseAddEpisodeModal = () => setopenAddTVEpisodeModal(false);






    const [openAddTagModal, setOpenAddTagModal] = React.useState(false);
    const handleOpenAddTagModal = () => {
        getTagOptions();
        setOpenAddTagModal(true);
    }
    const handleCloseAddTagModal = () => setOpenAddTagModal(false);

    const handleLanguageChange = (event: any) => {
        setSeriesLanguage(event.target.value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChangeTVTags = (event: SelectChangeEvent<typeof TVTags>) => {
        const {
            target: { value },
        } = event;
        setTVTags(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (!sessionStorage.getItem('supratvtoken')) {
            navigate('/login');
        }
        gettvseries();
        setLoading(false);
    }, [navigate]);

    // useEffect(() => {
    //     if (series[0]?.seasons) {
    //         // Create a scroll hook for each season in series[0]
    //         setScrolls(series[0].seasons.map(() => useHorizontalScroll()));
    //     }
    // }, [series]);

    // const scrolls = useMultipleHorizontalScrolls(series[0]?.seasons || []);

    // useEffect(() => {
    //     if (scrollRefs.current.length === seasons.length) {
    //         // Apply horizontal scroll logic here
    //         scrollRefs.current.forEach((scrollRef) => {
    //             if (scrollRef) {
    //                 useHorizontalScroll(scrollRef);
    //             }
    //         });
    //     }
    // }, [seasons]);

    // useEffect(() => {
    //     // Check if all files have been uploaded
    //     if (completedUploads === files.length && files.length > 0) {
    //         handleCloseAddEpisodeModal();
    //         gettvseries(); // Trigger the function when all files are done uploading
    //     }
    // }, [completedUploads, files]);

    
    const handleViewTVEpisode = (hash: string) => {
        navigate("/v/" + hash);
    };


    return (
        <>
            <SupraTVHeader />
            <Grid container spacing={2}>
                <Dialog
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            setLoading(true);
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());
                            await axios.put(APIURL + "/supratv/serie/" + serieid, formJson, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                                .then((response: any) => {
                                    gettvseries();
                                    setLoading(false);
                                })
                                .catch((e: any) => {
                                    setLoading(false);
                                });

                            handleCloseEditModal();
                        },
                    }}
                >
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Edit {series[0]?.tvsname}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvsname">
                                Series name
                            </InputLabel>
                            <OutlinedInput
                                id="tvsname"
                                name="tvsname"
                                type="text"
                                label="Series Name"
                                defaultValue={series[0]?.tvsname}
                                required
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvsyear">
                                Year
                            </InputLabel>
                            <OutlinedInput
                                id="tvsyear"
                                name="tvsyear"
                                type="number"
                                label="Year"
                                defaultValue={series[0]?.tvsyear}
                                required
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvslanguage">
                                Language
                            </InputLabel>
                            <Select
                                labelId="tvslanguage"
                                id="tvslanguage"
                                label="tvslanguage"
                                name="tvslanguage"
                                value={seriesLanguage || 'English'}
                                onChange={handleLanguageChange}
                                required
                            >
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Norwegian"}>Norwegian</MenuItem>
                                <MenuItem value={"Swedish"}>Swedish</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvsdescriptionshort">
                                Description Short
                            </InputLabel>
                            <OutlinedInput
                                id="tvsdescriptionshort"
                                name="tvsdescriptionshort"
                                type="text"
                                label="Description Short"
                                defaultValue={series[0]?.tvsdescriptionshort}
                                multiline
                                rows={5}
                                required
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvsdescription">
                                Description
                            </InputLabel>
                            <OutlinedInput
                                id="tvsdescription"
                                name="tvsdescription"
                                type="text"
                                label="Description"
                                defaultValue={series[0]?.tvsdescription}
                                multiline
                                rows={5}
                                required
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEditModal} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                        <Button type="submit" variant="outlined" startIcon={<EditIcon />}>Save</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openAddSeasonModal}
                    onClose={handleCloseAddSeasonModal}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            setLoading(true);
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());
                            await axios.post(APIURL + "/supratv/season/" + serieid, formJson, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                                .then((response: any) => {
                                    gettvseries();
                                    setLoading(false);
                                })
                                .catch((e: any) => {
                                    setLoading(false);
                                });
                            handleCloseAddSeasonModal();
                        },
                    }}
                >
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Add season on {series[0]?.tvsname}
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="tvsname">
                                Season name
                            </InputLabel>
                            <OutlinedInput
                                id="tvseasonname"
                                name="tvseasonname"
                                type="text"
                                label="Season name"
                                required
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddSeasonModal} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                        <Button type="submit" variant="outlined" startIcon={<EditIcon />}>Add</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    maxWidth="lg"
                    fullWidth
                    open={openAddTVEpisodeModal}
                    onClose={handleCloseAddEpisodeModal}
                >
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Add episodes on {series[0]?.tvsid}, season {activeSeasonId}
                        </DialogContentText>
                        <Paper elevation={0} sx={{ pr: 3, pl: 3, pt: 2, pb: 3 }}>
                            {!uploading && ( // Hide the upload box when uploading
                                <Box
                                    {...getRootPropsEpisode()}
                                    sx={{
                                        border: isDragActive ? '2px dashed #ffffff' : '2px dashed #cccccc',
                                        borderRadius: '4px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: isDragActive ? '#424242' : '#212121',
                                        transition: 'background-color 0.2s ease-in-out',
                                    }}
                                >
                                    <input {...getInputPropsEpisode()} />
                                    <Typography variant="h6" color="textSecondary">
                                        {isDragActive ?
                                            "Drop the files here ..." :
                                            "Drag & drop some files here, or click to select files"}
                                    </Typography>
                                </Box>
                            )}

                            {/* Show list of files and their upload progress */}
                            <Box sx={{ marginTop: '20px' }}>
                                {files.map((file) => (
                                    <Box key={file.name} sx={{ mb: 2, mr: 5, ml: 5 }}>
                                        <Typography variant="caption">{file.name} ({uploadProgress[file.name] || 0} %)</Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={uploadProgress[file.name] || 0}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddEpisodeModal} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={openAddTagModal}
                    onClose={handleCloseAddTagModal}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            setLoading(true);
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());
                            const formWithTags = {
                                ...formJson,
                                TVTags,
                            };
                            await axios.post(APIURL + "/supratv/tags/" + serieid, formWithTags, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                                .then((response: any) => {
                                    gettvseries();
                                    setLoading(false);
                                })
                                .catch((e: any) => {
                                    setLoading(false);
                                });
                            handleCloseAddTagModal();
                        },
                    }}
                >
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Add tag on {series[0]?.tvsname}
                        </DialogContentText>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel id="tags">Tag</InputLabel>
                            <Select
                                fullWidth
                                labelId="tags"
                                id="tagscheckbox"
                                multiple
                                value={TVTags}
                                onChange={handleChangeTVTags}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) =>
                                    selected
                                        .map(id => tagOptions.find(tag => tag.tvtagid === id)?.tvtagname)
                                        .join(', ')
                                }
                                MenuProps={MenuProps}
                            >
                                {tagOptions.map((tagOption) => (
                                    <MenuItem key={tagOption.tvtagid} value={tagOption.tvtagid}>
                                        <Checkbox checked={TVTags.indexOf(tagOption.tvtagid) > -1} />
                                        <ListItemText primary={tagOption.tvtagname} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddTagModal} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                        <Button type="submit" variant="outlined" startIcon={<EditIcon />}>Add</Button>
                    </DialogActions>
                </Dialog>
                <Grid size={12}>
                    <Box
                        {...getRootPropsBanner()}
                    >
                        <input {...getInputPropsBanner()} />
                        <img
                            src={imageUrl}
                            width='100%'
                            style={{ maxHeight: 500 }}
                        ></img>
                    </Box>
                </Grid>
                <Grid size={12} pr={1} pl={1}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid size="auto">
                                    <Box
                                        {...getRootPropsFront()}
                                    >
                                        <input {...getInputPropsFront()} />
                                        <img src={imageFrontUrl} style={{ width: 150, height: 'auto' }} />
                                    </Box>
                                </Grid>
                                <Grid size="grow">
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <div>
                                            <Typography variant="h6" component="h2">
                                                {series[0]?.tvsname} ({series[0]?.tvsyear})
                                            </Typography>
                                        </div>
                                        <div>
                                            <IconButton onClick={(handleOpenEditModal)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="large"
                                                edge="end"
                                                aria-label="Add new season"
                                                aria-haspopup="true"
                                                onClick={handleOpenAddSeasonModal}
                                                color="inherit"
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                            <IconButton
                                                size="large"
                                                edge="end"
                                                aria-label="Add new tag"
                                                aria-haspopup="true"
                                                onClick={handleOpenAddTagModal}
                                                color="inherit"
                                            >
                                                <TagIcon />
                                            </IconButton>
                                        </div>
                                    </Box>
                                    <Typography variant="caption" component="h2">
                                        {series[0]?.tvsdescription}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        {series[0]?.tags && series[0].tags.map((tag: any) => (
                                            <Chip label={tag?.tvtagname} variant="outlined" onDelete={e => handleDeleteTag(tag?.tvstagid)} sx={{ mr: 1 }} />
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {series[0]?.seasons && series[0].seasons.map((season: any, seasonIndex: number) => {
                        return (
                            <div key={`season-${seasonIndex}`}>
                                <Typography variant="h6" sx={{ ml: 2, mt: 1 }}>
                                    Season {season.tvseasonname}
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        aria-label="Add new episodes"
                                        aria-haspopup="true"
                                        onClick={() => handleOpenAddEpisodeModal(season?.tvseasonid, season?.tvseasonname)}
                                        color="inherit"
                                    >
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Typography>
                                <SupraTVHorizontalScroll sx={{ p: 2, bgcolor: 'background.paper' }}>
                                    {season.episodes && season.episodes.map((episode: any, episodeIndex: number) => (
                                        <Card
                                            key={`episode-${episodeIndex}`}
                                            onClick={() => handleViewTVEpisode(episode.tvefilesha1)}
                                            sx={{
                                                minWidth: '250px',
                                                maxWidth: '250px',
                                                cursor: 'pointer',
                                                flexShrink: 0,
                                                transition: 'transform 0.3s ease',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                },
                                            }}
                                        >
                                            <SupraTVFrontImage
                                                src={"http://10.1.80.10/series/" + episode.tveurlthumb}
                                                alt={series[0]?.tvsname}
                                                fallback="images/front/unknown.jpg"
                                                overlayText={episode.tvename}
                                            />
                                        </Card>
                                    ))}
                                </SupraTVHorizontalScroll>
                            </div>
                        );
                    })}
                </Grid>
            </Grid >
        </>
    );
}

export default TVSerie;
